export default {
  path: 'greyList',
  name: 'greyList',
  meta: {
    id: '',
    name: 'greyList',
    title: '灰名单Api列表',
    apis: [
      'get:/operation/v1/user/current'
    ]
  }
}
