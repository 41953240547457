import Vue from 'vue'
import VueRouter from 'vue-router'
import MainView from '@/components/ft-frame.vue'
import LoginView from '@/webapp/login/index.vue'
import routerMain from './router.main'
import currentUser from '@/utils/current_user'

const vuePush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return vuePush.call(this, location).catch(err => err)
}

const { routerList } = routerMain

const flattenTree = (data, prefix) => {
  const list = data.reduce((pre, item) => [...pre, item, ...flattenTree(item.children || [], item.path)], [])
  list.forEach(row => {
    row.aPath = prefix + (row.aPath || row.path)
  })
  return list
}
const flatRoutes = flattenTree(routerList, '')

const routers = flatRoutes.map(row => {
  return {
    id: row.id,
    path: row.aPath,
    component: row.component
  }
})

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: LoginView
  },
  {
    path: '/',
    name: 'home',
    component: MainView,
    children: routers
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/webapp/error.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // 登录判断
  const token = currentUser.token
  if (to.path !== '/login' && !token) {
    router.push('/login')
  } else {
    next()
  }
})

export default router
