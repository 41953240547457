
import CryptoJs from 'crypto-js'
import encHex from 'crypto-js/enc-hex'

export function isObject (obj) {
  return obj !== null && typeof obj === 'object'
}

export function isArray (obj) {
  if (!Array.isArray) {
    Array.isArray = function (arg) {
      return Object.prototype.toString.call(arg) === '[object Array]'
    }
  }
  return Array.isArray(obj)
}

export function each (obj, iterator) {
  if (isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      iterator.call(obj[i], obj[i], i)
    }
  } else if (isObject(obj)) {
    for (const key in obj) {
      if ({}.hasOwnProperty.call(obj, key)) {
        iterator.call(obj[key], obj[key], key)
      }
    }
  }
}

/**
 * 将关键字变为红色
 * @param str  待处理字符串
 * @param keyWord   关键字字符串
 * @returns {*} 将关键字变为红色加粗后的字符串
 */
export function changeKeyRed (str, keyWord) {
  if (str != null && keyWord != null) {
    const substr = '/' + keyWord + '/g'
    // eslint-disable-next-line no-eval
    const replaceStr = str.replace(eval(substr), "<span style='color:red;font-weight:bold'>" + keyWord + '</span>')
    return replaceStr
  } else {
    return str
  }
}

export const mergeData = (...args) => {
  return args.reduce((obj, data) => {
    each(data, (value, prop) => {
      const objValue = obj[prop]
      if (prop === 'on') {
        const objEvents = obj[prop] = objValue || {}
        each(value, (cb, event) => {
          if (objEvents[event]) {
            const objEvent = objEvents[event]
            objEvents[event] = function (...args) {
              const result = cb.apply(null, args)
              // 传进来的事件返回false可以阻止默认事件执行
              if (result !== false) {
                return objEvent.apply(null, args)
              }
            }
          } else {
            objEvents[event] = cb
          }
        })
      } else if (isObject(objValue)) {
        obj[prop] = Object.assign(objValue, value || {})
      } else if (isArray(objValue)) {
        obj[prop] = objValue.concat(value || [])
      } else {
        obj[prop] = value
      }
    })
    return obj
  }, {})
}

/** 获取图片src，可传入图片链接或文件对象 */
export const createObjectURL = url => {
  // File 对象
  if (url && typeof url !== 'string') {
    url = window.URL.createObjectURL(url)
  }
  return url
}

export const revokeObjectURL = (url) => {
  if (/^blob:/.test(url) && window.URL) {
    window.URL.revokeObjectURL(url)
  }
}

/**
 * 计算文件的 md5 hash 值
 * @param {File} file
 */
export const hashFile = async (file) => {
  /**
     * 使用指定的算法计算hash值
     */
  function hashFileInternal (file, alog) {
    // 指定块的大小，这里设置为20MB,可以根据实际情况进行配置
    const chunkSize = 20 * 1024 * 1024
    let promise = Promise.resolve()
    // 使用promise来串联hash计算的顺序。因为FileReader是在事件中处理文件内容的，必须要通过某种机制来保证update的顺序是文件正确的顺序
    for (let index = 0; index < file.size; index += chunkSize) {
      promise = promise.then(() => hashBlob(file.slice(index, index + chunkSize)))
    }

    /**
       * 更新文件块的hash值
       */
    function hashBlob (blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = ({ target }) => {
          const wordArray = CryptoJs.lib.WordArray.create(target.result)
          // 增量更新计算结果
          alog.update(wordArray)
          resolve()
        }
        reader.readAsArrayBuffer(blob)
      })
    }

    // 使用promise返回最终的计算结果
    return promise.then(() => encHex.stringify(alog.finalize()))
  }

  // 同时计算文件的sha256和md5,并使用promise返回
  return Promise.all([hashFileInternal(file, CryptoJs.algo.SHA256.create()),
    hashFileInternal(file, CryptoJs.algo.MD5.create())])
    .then(([sha256, md5]) => ({
      sha256,
      md5
    }))
}

/**
 * 统计文本中的字数
 * 只计算英文单词，中文和全角符号（空格和全角空格不计算）
 * @param {String} text
 */
export function getTextCount (text) {
  let count = 0
  if (text && text.length > 0) {
    // 计算英文单词
    // eslint-disable-next-line no-control-regex
    const b = text.replace(/[\x00-\x7e]+/gm, function (val) {
      val.replace(/[^\t\s]+/gm, function (val) {
        count++
      })
      return ''
    })
    // 计算中文及全角符号
    const c = b.replace(/[\u3000\t\s]*/gm, function (val) { return '' })
    count += c.length
  }
  return count
}

/**
 * 数字转成汉字
 * @params num === 要转换的数字
 * @return 汉字
 * */
export function toChinesNum (num) {
  const changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  const unit = ['', '十', '百', '千', '万']
  num = parseInt(num)
  const getWan = (temp) => {
    const strArr = temp.toString().split('').reverse()
    let newNum = ''
    const newArr = []
    strArr.forEach((item, index) => {
      newArr.unshift(item === '0' ? changeNum[item] : changeNum[item] + unit[index])
    })
    const numArr = []
    newArr.forEach((m, n) => {
      if (m !== '零') numArr.push(n)
    })
    if (newArr.length > 1) {
      newArr.forEach((m, n) => {
        if (newArr[newArr.length - 1] === '零') {
          if (n <= numArr[numArr.length - 1]) {
            newNum += m
          }
        } else {
          newNum += m
        }
      })
    } else {
      newNum = newArr[0]
    }

    return newNum
  }
  const overWan = Math.floor(num / 10000)
  let noWan = num % 10000
  if (noWan.toString().length < 4) {
    noWan = '0' + noWan
  }
  return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
}
