import { city, province, area } from './area'
export const AREA_TREE = province.map(row => {
  let v = false
  let cityList = city.filter(r => r.p === row.p)
  if (cityList.length === 0) {
    v = true
    cityList = area.filter(rr => rr.p === row.p)
  }
  return {
    label: row.n,
    value: row.c,
    children: cityList.map(r => {
      return {
        label: r.n,
        value: r.c,
        children: v
          ? null
          : area.filter(rr => rr.p === r.p && rr.t === r.t).map(rr => {
            return {
              label: rr.n,
              value: r.c
            }
          })
      }
    })
  }
})

export const ID_TYPE = [null, '居民身份证']

export const ENTRUST_TYPE = [null, '个人', '企业']

export const DISPUTE_TYPE = [null, '合同纠纷', '债务纠纷', '保险纠纷', '公司纠纷', '知识产权纠纷']

export const APPLY_STATUS = ['待受理', '受理中', '申请通过', '申请驳回']
