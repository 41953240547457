<script>
import { mergeData, revokeObjectURL, createObjectURL } from '@/utils/tool'

const defaults = {}
const normalizeStyle = value => typeof value === 'number' ? value + 'px' : value

export default {
  name: 'jw-cropper',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    src: {
      // type: [String, File]
      required: true
    },
    showDialog: {
      type: Boolean,
      default: true
    },
    openDelay: {
      type: Number,
      default: 0
    },
    bindDialog: {
      type: Object,
      default: () => ({})
    },
    dialogWidth: {
      type: [Number, String]
    },
    options: {
      type: Object,
      default: () => ({})
    },
    aspectRatio: {
      type: Number,
      default: 1 / 1
    },
    minCropBoxWidth: Number,
    minCropBoxHeight: Number,
    width: [Number, String],
    height: [Number, String],
    background: {
      type: String,
      default: 'white',
      validator (background) {
        return ['white', 'transparent'].indexOf(background) > -1
      }
    },
    originalSize: {
      type: Boolean,
      defalut: false
    }
  },
  data () {
    return {
      isVisible: this.visible,
      isOriginalSize: this.originalSize
    }
  },
  computed: {
    imgSrc () {
      return createObjectURL(this.src)
    }
  },
  watch: {
    visible (visible) {
      this.isVisible = visible
    },
    imgSrc (imgSrc, oldSrc) {
      this.replace(imgSrc, oldSrc)
    }
  },
  render (h) {
    const style = {}
    if (this.width) {
      style.width = normalizeStyle(this.width)
    }
    if (this.height) {
      style.height = normalizeStyle(this.height)
    }
    const el = (
      <div class={['jw-cropper', `jw-cropper__${this.background}`]} style={style}>
        <img ref='img' src={this.imgSrc} alt='待裁剪图片' />
      </div>
    )
    if (!this.showDialog) {
      return el
    }
    const self = this
    const defaultData = {
      props: {
        title: '编辑图片',
        visible: this.isVisible,
        appendToBody: true,
        closeOnClickModal: false,
        width: normalizeStyle(this.dialogWidth)
      },
      on: {
        open: self.handleDialogOpen,
        'update:visible': self.updateVisible
      }
    }
    const data = mergeData(defaultData, this.bindDialog)
    data.props.customClass = ((data.props.customClass || '') + ' jw-cropper-dialog').trim()
    return (
      <el-dialog
        {...data}
      >
        { el }
        <span slot='footer' class='dialog-footer'>
          <el-checkbox value={this.isOriginalSize} on-change={(value) => { this.isOriginalSize = value }} style="float: left;">以原图大小裁剪</el-checkbox>
          <el-button on-click={this.close}>取 消</el-button>
          <el-button type='primary' on-click={this.cropAndClose}>确 定</el-button>
        </span>
      </el-dialog>
    )
  },
  mounted () {
    if (!window.Cropper) {
      throw new Error('请先引入 cropperjs')
    }
    if (!this.showDialog) {
      this.init()
    }
    this.$nextTick(() => {
      if (this.isVisible) {
        this.handleDialogOpen()
      }
    })
  },
  methods: {
    init () {
      if (this.cropper) {
        return
      }
      const aspectRatio = this.aspectRatio
      let minCropBoxWidth = this.minCropBoxWidth
      let minCropBoxHeight = this.minCropBoxHeight
      if (minCropBoxWidth && !minCropBoxHeight) {
        minCropBoxHeight = minCropBoxWidth / aspectRatio
      }
      if (!minCropBoxWidth && minCropBoxHeight) {
        minCropBoxWidth = minCropBoxHeight * aspectRatio
      }
      const options = Object.assign({}, defaults, this.options, {
        aspectRatio,
        minCropBoxWidth,
        minCropBoxHeight,
        background: this.background === 'transparent',
        autoCrop: false,
        ready: this.handleReady
      })
      // cropperjs: https://github.com/fengyuanchen/cropperjs
      this.cropper = new window.Cropper(this.$refs.img, options)
      this.$emit('init', this.cropper)
    },
    handleReady () {
      const { minCropBoxWidth, minCropBoxHeight } = this.cropper.options
      const containerData = this.cropper.getContainerData()
      const left = (containerData.width - minCropBoxWidth) / 2
      const top = (containerData.height - minCropBoxHeight) / 2
      this.cropper.crop()
      this.cropper.setCropBoxData({
        left,
        top,
        width: minCropBoxWidth,
        height: minCropBoxHeight
      })
    },
    replace (src, oldSrc) {
      const img = this.$refs.img
      if (!oldSrc) {
        oldSrc = img && img.getAttribute('src')
      }
      revokeObjectURL(oldSrc)
      src = createObjectURL(src)
      if (this.showDialog) {
        img && img.setAttribute('src', src)
        this.cropper && this.cropper.destroy()
        this.cropper = null
      } else {
        this.cropper && this.cropper.replace(src)
      }
    },
    crop (options) {
      const data = this.cropper.getCropBoxData()
      const defaultOptions = {
        width: this.isOriginalSize ? null : data.width,
        height: this.isOriginalSize ? null : data.height,
        mageSmoothingEnabled: true,
        mageSmoothingQuality: 'high'
      }
      if (this.background === 'white') {
        defaultOptions.fillColor = '#fff'
      }
      return this.cropper.getCroppedCanvas(Object.assign(defaultOptions, options))
    },
    cropAndClose () {
      this.$emit('crop', this.crop())
      this.close()
    },
    close () {
      this.updateVisible(false)
    },
    updateVisible (visible) {
      this.$emit('update:visible', visible)
    },
    handleDialogOpen () {
      setTimeout(() => {
        this.init()
      }, this.openDelay)
    }
  }
}
</script>

<style lang="less">
.jw-cropper{
  img {
    max-width: 100%;
  }
  &.jw-cropper__white {
    .cropper-view-box {
      background-color: #fff;
    }
    .cropper-modal {
      background-color: #333;
      opacity: 0.1;
    }
  }
}
</style>
