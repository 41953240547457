// 首页
export default [
  {
    path: '/home',
    meta: {
      icon: '',
      name: 'home',
      title: '首页',
      code: 'home'
    },
    children: [
      {
        path: '/mediation-center',
        meta: {
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'mediation-center',
          title: '调解中心管理',
          code: 'home:mediation'
        },
        component: () => import('@/webapp/home/center.vue')
      },
      {
        path: '/user',
        meta: {
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'mediation-center',
          title: '用户管理',
          code: 'home:user'
        },
        component: () => import('@/webapp/home/user.vue')
      }

    ]

  }
]
