export default {
  path: 'whiteList',
  name: 'whiteList',
  meta: {
    id: '',
    name: 'greyList',
    title: '白名单Api列表',
    apis: [

    ]
  }
}
