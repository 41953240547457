import Vue from 'vue'
import ElementUI, { Message } from 'element-ui'
import '@/assets/css/theme/index.css'
import '@/assets/css/common.less'
import App from './App.vue'
import router from './router'
import filters from '@/utils/filter'
import axios from 'axios'
import ftComponent from '@/components/index.js'
import currentUser from '@/utils/current_user'
axios.defaults.baseURL = '/mediation/api/v1'

axios.interceptors.request.use((config) => {
  const token = currentUser.token
  if (token) {
    config.headers.accessToken = token
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(res => {
  if (res?.data?.code > 200 && res?.data?.message) {
    if (res?.data?.code === 401) {
      router.push('/login')
      Message.error('登录已失效，请重新登录')
      return Promise.reject(new Error('' + res?.data?.message))
    } else {
      ElementUI.Message.error('' + res?.data?.message)
      return Promise.reject(new Error('' + res?.data?.message))
    }
  } else {
    return Promise.resolve(res)
  }
})

Vue.prototype.$axios = axios
Vue.prototype.$currentUser = currentUser
Vue.config.productionTip = false
Vue.use(filters)
Vue.use(ElementUI, { size: 'small' })
Vue.use(ftComponent)
Vue.mixin({
  methods: {
    getPermissionList () {
      return this.$currentUser.permission
    },
    hasPermission (code) {
      const permission = this.getPermissionList()
      return permission.some(row => {
        return row.startsWith(code)
      })
    }
  }
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
