<template>
  <div class="login">
    <div class="header">
      <div class="logo"></div>
      <div class="split"></div>
      <div class="title">调解中心运营后台管理系统</div>
    </div>
    <div class="content">
      <div class="midd">
        <div class="form" @keypress.enter="login">
          <div class="title">欢迎登录-调解中心运营后台管理系统</div>
          <el-form ref="ruleForm"  :hide-required-asterisk="true" :model="ruleForm" :rules="rules" class="login-form" >
            <el-form-item class="item" prop="phone">
              <template slot="label">
                <i class="iconfont icon-dengluzhanghao"/>
                <label class="label">登录帐号</label>
              </template>
              <el-input size="nomarl" v-model="ruleForm.phone" placeholder="请输入登录账号"></el-input>
            </el-form-item>
            <el-form-item class="item" prop="password">
              <template slot="label">
                <i class="iconfont icon-denglumima"/>
                <label class="label">登录密码</label>
              </template>
              <el-input size="nomarl" type="password" v-model="ruleForm.password" placeholder="请输入登录密码"></el-input>
            </el-form-item>
            <el-form-item class="item" prop="code">
              <template slot="label">
                <i class="iconfont icon-tuxingyanzhengma"/>
                <label class="label">图形验证码</label>
              </template>
              <div>
                <div class="code-content">
                  <el-input size="nomarl" v-model="ruleForm.code" placeholder="请输入图形验证码"></el-input>
                  <div ref="codeImgeEl" class="code" @click="refreshCode">
                    <img :src="codeUrl" v-if="codeUrl"/>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="item" style="margin-top:20px">
              <el-button @click="login" class="login-button">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="floor">
           Copyright  2022-2032 湖南法图科技有限公司 备案号: 湘ICP备2023009052号-1
        </div>
    </div>

  </div>
</template>
<script>
export default {
  data () {
    return {
      ruleForm: {
        phone: '',
        password: '',
        code: ''
      },
      text: '',
      rules: {
        phone: [
          { required: true, message: '请输入登录账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      codeUrl: ''
    }
  },
  async mounted () {
    this.refreshCode()
  },
  methods: {
    async refreshCode () {
      const res = await this.$axios.get('/user/verify/code', { responseType: 'blob' })
      this.codeUrl = URL.createObjectURL(res.data)
    },
    async login () {
      this.loading = true
      try {
        await this.$refs.ruleForm.validate()
        const res = await this.$axios.post('/user/login', null, { params: { password: this.ruleForm.password, username: this.ruleForm.phone, code: this.ruleForm.code } })
        this.$currentUser.token = res.data.result.accessToken
        this.$currentUser.permission = res.data.result.permissions
        this.$currentUser.loadData()
        this.$router.replace({ path: '/' })
      } finally {
        this.refreshCode()
        this.loading = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  background-color: #4660d2;
  display: flex;
  flex-direction: column;
  .header {
    height: 60px;
    background-color: #fff;
    padding: 6px 20px;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    .logo {
      background-image: url(@/assets/logo.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      flex: 0 0 133px;
      margin-top: 6px;
    }
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 18px;
      font-weight: 800;
    }
    .split {
      height: 36px;
      width: 1px;
      margin: 6px 20px;
      background-color: #ebebeb;
    }
  }
  .content {
    flex: 1 auto;
    display: flex;
    background-image: url(@/assets/images/login-bg.png);
    flex-direction: column;
    .form {
      width: 500px;
      height: 530px;
      background: #ffffff;
      border-radius: 8px;
      padding: 60px;
      box-sizing: border-box;
      .item {
        margin-bottom: 10px;
      }
      .login-button {
        width: 380px;
        height: 44px;
        background: #3350cb;
        border-radius: 4px;
        color: #fff;
        margin-top: 10px;

      }
      .title {
        font-size: 26px;
        font-weight: 600;
        text-align: center;
        color: rgba(42,42,44,0.98);
        line-height: 37px;
      }
      .code-content {
        display: flex;
        flex-direction: row;
        height: 40px;
        width: 100%;
        .code {
          margin-left: 10px;
          flex-direction: column;
          display: flex;
          justify-content: center;
          align-items: center;
          /deep/ svg {
            width: 120px;
            height: 40px;
            background-color: #E6E7EF;
          }

        }
      }
      .login-form {
        margin-top: 40px;
        font-size: 14px;
        font-weight: 400;
        .icon {
          vertical-align: middle;
        }
        .label {
          margin-left: 8px;
        }
        .iconfont {
          color: #c7c8d0;
        }
      }
    }
    .midd {
      display: flex;
      flex: 1 auto;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .floor {
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      flex: 0 40px;
      line-height: 40px;
      text-align: center;
      font-size: 14px;
    }
  }
}
</style>
