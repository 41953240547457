<template>
  <el-cascader
    filterable
    :value="displayValue"
    @input="handleChangeValue"
    :props="{ value: 'label', label: 'label'}"
    :options="options">
  </el-cascader>
</template>

<script>
import { AREA_TREE } from '@/utils/constant.js'

export default {
  props: {
    value: {
      type: String,
      default () {
        return null
      }
    }
  },
  computed: {
    options () {
      return AREA_TREE
    },
    displayValue () {
      return (this.value || '').split(',')
    }
  },
  methods: {
    handleChangeValue (val) {
      this.$emit('input', (val || []).join(','))
    }
  }
}
</script>

<style lang="less" scoped>

</style>
