<template>
    <div class="inside-page">
        <div class="inside-page-title">
            <div class="inside-page-header-title" >
              <template v-if="$slots.title">
                <slot name="title"/>
              </template>
              <template v-else>
                {{ title }}
              </template>
            </div>
            <div class="inside-page-header-tools" v-if="$slots.tools">
              <slot name="tools"/>
            </div>
        </div>
        <div class="inside-page-content">
            <slot/>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default () {
        return '自定义标题'
      }
    }
  }
}
</script>
<style scoped lang="less">
.inside-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  .inside-page-title {
    flex: 0 20px;
    height: 20px;
    padding: 14px 20px;
    border-bottom: 1px solid #e6e7ef;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #2a2b2f;
    display: flex;
    flex-direction: row;
    .inside-page-header-title {
      flex: 1 1 auto;
    }
    .inside-page-header-tool {
      flex: 0 0 auto;
    }
  }
  .inside-page-content {
    flex: 1 auto;
    padding: 20px 20px;
  }
}
</style>
