
const hasNoIdFromChildren = (menu) => {
  if (!menu.children || menu.children.length === 0) {
    return !menu.code
  } else {
    return menu.children.some(row => hasNoIdFromChildren(row))
  }
}

export default {
  /**
   * 根据路由配置获取菜单数据
   * @param {*} routers 路由配置
   * @param {*} root 根路径，如果没有就以 '/' 为根路径
   * @param {function} permissionCb 更具权限id判断权限
   */
  getMenus (routers, root, permissionCb) {
    const menus = []
    routers.forEach((row, index) => {
      if (!row.meta) return
      let path = row.path
      if (path.startsWith('/')) {
        path = path.substring(1)
      }
      const aPath = (root || '') + '/' + path
      const menu = {
        // 菜单名称
        title: row.meta.title,
        // 菜单图标,
        icon: row.meta.icon,
        iconColor: row.meta.iconColor || '#000',
        // 访问路径
        path: aPath,
        option: row.meta.option,
        component: row.component,
        code: row.meta.code,
        expand: true,
        children: row.children && row.children.length > 0 ? this.getMenus(row.children, aPath, permissionCb) : [] // 子菜单
      }
      if (!menu.code || hasNoIdFromChildren(menu) || permissionCb(menu.code)) {
        menus.push(menu)
      }
    })
    return menus
  }
}
