import ftPage from './ft-page.vue'
import editDialog from './edit-dialog.vue'
import ftAreaSelect from './ft-area-select.vue'
import ftUpload from './ft-upload.vue'
import ftEditor from './ft-editor-one.vue'
export default {
  install (Vue) {
    Vue.component('ftPage', ftPage)
    Vue.component('editDialog', editDialog)
    Vue.component('ftAreaSelect', ftAreaSelect)
    Vue.component('ftUpload', ftUpload)
    Vue.component('ftEditor', ftEditor)
  }
}
